import React from "react";
import styles from "./SearchBar.module.scss";
import { CiSearch } from "react-icons/ci";

export const SearchBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <CiSearch className={styles.icon} />
      </div>
      <input className={styles.input} placeholder="Caută..."></input>
    </div>
  );
};

export default SearchBar;
