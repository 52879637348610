import React from "react";
import Image from "next/image";
import clsx from "clsx/lite";
import { IoArrowForward } from "react-icons/io5";
import styles from "./Buttons.module.scss";

export const BookAppointmentButton = () => {
  return (
    <button className={clsx(styles.navbarBtn, styles.bookAppointmentRtn)}>
      <span className={styles.imgWrapper}>
        <Image src="/images/stethoscope.svg" alt="about" fill />
      </span>
      <p className={styles.text}>Programeaza-te Online</p>
      <IoArrowForward className={styles.arrow} />
    </button>
  );
};

export default BookAppointmentButton;
