import React, { ReactNode } from "react";
import { IoCloseOutline } from "react-icons/io5";

import styles from "./ScheduleTable.module.scss";
import clsx from "clsx/lite";
import { capitalize } from "@/utils/strings";
import { ScheduleData } from "@/lib/strapiApiTypes/schedule";
import { Modal } from "@mui/material";

type WrapperProps = { children: ReactNode; onClose?: () => void };

const ModalWrapper = ({ children, onClose }: WrapperProps) => {
  if (onClose) {
    return (
      <div className={styles.overlay}>
        <div className={styles.backdrop} onClick={onClose} />
        {children}
      </div>
    );
  } else {
    return children;
    return <div className={styles.noModalWrapper}>{children}</div>;
  }
};

type Props = {
  onClose?: () => void;
  isAboutPage?: boolean;
  scheduleData: ScheduleData;
};

/**
 * ScheduleTable component displays a table showing the opening and closing times for each day of the week.
 * It can optionally be wrapped in a modal overlay when provided with an onClose function.
 *
 * @component
 * @example
//  * // Usage example with modal functionality:
 * <ScheduleTable onClose={() => console.log('Modal closed')} />
 *
 * @example
//  * Usage example without modal functionality:
 * <ScheduleTable />
 *
 * @param {Object} props - The props object.
 * @param {Function} [props.onClose] - Optional function to call when the modal overlay or close button is clicked. If provided, the component will render inside a modal.
 *
 * @returns {JSX.Element} A table component displaying the schedule for the week, optionally within a modal.
 */
export default function ScheduleTable({
  onClose,
  isAboutPage,
  scheduleData,
}: Props) {
  return (
    <ModalWrapper onClose={onClose}>
      <div
        className={`${styles.container} ${
          isAboutPage && styles.scheduleAboutPage
        }`}
      >
        <div className={styles.header}>
          <h1 className={clsx(styles.title, !onClose && styles.coloredTitle)}>
            Program de funcționare
          </h1>
          {onClose && (
            <button className={clsx(styles.closeButton)} onClick={onClose}>
              <IoCloseOutline className={styles.closeButtonIcon} />
            </button>
          )}
        </div>
        <div className={styles.body}>
          {scheduleData.schedules.map((schedule, index) => (
            <div
              className={styles.departmentSchedule}
              key={`${schedule.department}-${index}`}
            >
              <p>{schedule.department}</p>
              <table className={styles.scheduleTable}>
                <colgroup>
                  <col className={styles.colDay} />
                  <col className={styles.colTime} />
                  <col className={styles.colTime} />
                </colgroup>
                <thead className={styles.tableHeader}>
                  <tr>
                    <th scope="col" className={styles.tableCell}>
                      Ziua
                    </th>
                    <th scope="col" className={styles.tableCell}>
                      De la
                    </th>
                    <th scope="col" className={styles.tableCell}>
                      Pana la
                    </th>
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {schedule.days.map((daySchedule) => {
                    const day = daySchedule.day
                      .split("-")
                      .map((w) => capitalize(w.trim()))
                      .join(" - ");

                    return (
                      <tr key={`${schedule.department} - ${daySchedule.day}`}>
                        <th scope="row" className={styles.tableCell}>
                          {day}
                        </th>
                        <td className={styles.tableCell}>
                          {daySchedule.closed
                            ? "Inchis"
                            : daySchedule.open.slice(0, 5)}
                        </td>
                        {!daySchedule.closed && (
                          <td className={styles.tableCell}>
                            {daySchedule.closed
                              ? "Inchis"
                              : daySchedule.close.slice(0, 5)}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
}
