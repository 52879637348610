"use client";

import { useEffect, useState } from "react";

const useWindowSize = () => {
  // Create a state to store the window width
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const [windowHeight, setWindowHeight] = useState<number | undefined>(
    undefined
  );

  // Update the width when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    handleResize();

    // Add event listener to window resize
    window.addEventListener("resize", handleResize);

    // Cleanup listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this runs only on mount/unmount

  return { windowWidth, windowHeight };
};

export default useWindowSize;
