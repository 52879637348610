import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/shared/Links/LocationLink/AdaptiveLocationLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Navigation/Header/InfoBar/InfoBar.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Navigation/Header/Buttons/Buttons.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Navigation/Header/NavBar/NavBar.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Navigation/Header/Header.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/shared/Navigation/Header/NavigationButtons/NavigationButtons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/shared/Navigation/Header/NavigationDrawer/NavigationDrawer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Navigation/SocialMediaLinks/SocialMediaLinks.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Navigation/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/DecTech/FooterTradeMark/FooterTradeMark.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/shared/Schedule/ScheduleButton/ScheduleButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"public/fonts/Satoshi/satoshi.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Satoshi-Variable.woff2\",\"weight\":\"300 900\",\"style\":\"normal\"},{\"path\":\"./fonts/Satoshi-VariableItalic.woff2\",\"weight\":\"300 900\",\"style\":\"italic\"}],\"variable\":\"--font-satoshi\"}],\"variableName\":\"satoshiFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/footerBg.png");
