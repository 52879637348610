import React from "react";
import styles from "./SocialMediaLinks.module.scss";
import { LuFacebook, LuInstagram, LuLinkedin, LuTwitter } from "react-icons/lu";

type Props = {
  socialNetworks: {
    id: number;
    url: string;
    platforma: string;
  }[];
};

export const SocialMediaLinks = ({ socialNetworks }: Props) => {
  const platforms = socialNetworks?.map(
    (socialNetwork) => socialNetwork.platforma
  );

  return (
    <div className={`${styles.row} ${styles.rowLinks}`}>
      <a
        className={styles.iconLink}
        href={
          socialNetworks?.find(
            (socialNetwork) => socialNetwork.platforma === "facebook"
          )?.url
        }
        target="_blank"
        aria-label="Buton catre contul de Facebook al Spitalului BalneoMedCenter"
      >
        <LuFacebook className={styles.icon} aria-hidden="true" />
      </a>
      {platforms?.includes("linkedin") && (
        <a
          className={styles.iconLink}
          href={
            socialNetworks?.find(
              (socialNetwork) => socialNetwork.platforma === "linkedin"
            )?.url
          }
          target="_blank"
          aria-label="Buton catre contul de LinkedIn al Spitalului BalneoMedCenter"
        >
          <LuLinkedin className={styles.icon} aria-hidden="true" />
        </a>
      )}
      {platforms?.includes("twitter") && (
        <a
          className={styles.iconLink}
          href={
            socialNetworks?.find(
              (socialNetwork) => socialNetwork.platforma === "twitter"
            )?.url
          }
          target="_blank"
          aria-label="Buton catre contul de Twitter (X) al Spitalului BalneoMedCenter"
        >
          <LuTwitter className={styles.icon} aria-hidden="true" />
        </a>
      )}
      {platforms?.includes("instagram") && (
        <a
          className={styles.iconLink}
          href={
            socialNetworks?.find(
              (socialNetwork) => socialNetwork.platforma === "instagram"
            )?.url
          }
          target="_blank"
          aria-label="Buton catre contul de Instagram al Spitalului BalneoMedCenter"
        >
          <LuInstagram className={styles.icon} aria-hidden="true" />
        </a>
      )}
    </div>
  );
};

export default SocialMediaLinks;
