import styles from "./NavigationDrawer.module.scss";
import { ComponentType } from "react";
import clsx from "clsx/lite";

type IconComponentProps = {
  className?: string;
};

type ContactLinkProps = {
  href: string;
  icon: ComponentType<IconComponentProps>;
  text: string;
};

const ContactLink = (props: ContactLinkProps) => {
  return (
    <a href={props.href} className={styles.contactLink}>
      <props.icon className={clsx(styles.smallIcon, styles.contactIcon)} />
      {props.text}
    </a>
  );
};

export default ContactLink;
