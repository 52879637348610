"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { SwipeableDrawer } from "@mui/material";
import clsx from "clsx/lite";

import useWindowSize from "@/hooks/useWindowSize";

import { RiMenu4Line } from "react-icons/ri";
import { PiAmbulance, PiFirstAidBold, PiPhoneLight } from "react-icons/pi";
import { HiOutlineNewspaper } from "react-icons/hi";
import { TfiVideoCamera } from "react-icons/tfi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FaVirusCovidSlash } from "react-icons/fa6";
import { GrGroup } from "react-icons/gr";
import { CiMail } from "react-icons/ci";
import {
  IoCloseOutline,
  IoDiamondOutline,
  IoLocationOutline,
} from "react-icons/io5";

import styles from "./NavigationDrawer.module.scss";

import SearchBar from "@/components/shared/Inputs/SearchBar/SearchBar";
import AnalysisResultsButton from "../Buttons/AnalysisResultsButton";
import BookAppointmentButton from "../Buttons/BookAppointmentButton";

import SocialMediaLinks from "../../SocialMediaLinks/SocialMediaLinks";
import ScheduleButton from "../../../Schedule/ScheduleButton/ScheduleButton";
import DrawerLink from "./DrawerLink";
import ContactLink from "./ContactLink";
import { ContactData } from "@/lib/strapiApiTypes/contact";
import { ScheduleData } from "@/lib/strapiApiTypes/schedule";
import AdaptiveLocationLink from "@/components/shared/Links/LocationLink/AdaptiveLocationLink";
import CloseButton from "@/components/shared/Buttons/CloseButton/CloseButton";

import variables from "@/styles/variables_export.module.scss";

type Props = {
  contactData: ContactData;
  scheduleData: ScheduleData;
};

const NavigationDrawer = ({ scheduleData, contactData }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const pathname = usePathname();
  const { windowWidth } = useWindowSize();

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [pathname]);

  return (
    <>
      <button
        className={clsx(styles.drawerActionButton, styles.menuButtonWrapper)}
        onClick={handleDrawerOpen}
        aria-label="Buton pentru deschiderea meniului"
      >
        <RiMenu4Line className={styles.icon} />
      </button>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <div className={styles.drawerContainer}>
          <div className={styles.closeButtonContainer}>
            <button
              className={clsx(
                styles.drawerActionButton,
                styles.closeDrawerButton
              )}
              onClick={handleDrawerClose}
            >
              <IoCloseOutline className={styles.smallIcon} />
            </button>
          </div>
          <div className={styles.drawerContentContainer}>
            <SearchBar />
            <div className={styles.buttonsContainer}>
              <BookAppointmentButton />
              <AnalysisResultsButton />
            </div>
            <div className={styles.section}>
              <DrawerLink
                href="/specialitati-medicale"
                icon={PiFirstAidBold}
                arrow={true}
                text="Specialități medicale"
              />
              <DrawerLink
                href="/cadre-medicale"
                icon={GrGroup}
                arrow={true}
                text="Cadre medicale"
              />
            </div>
            <div className={styles.section}>
              <DrawerLink href="/despre-noi" icon={GrGroup} text="Despre noi" />
              <div className={styles.sectionRow}>
                <DrawerLink
                  href="/noutati"
                  icon={HiOutlineNewspaper}
                  text="Noutăți"
                />
                <DrawerLink href="#" icon={TfiVideoCamera} text="Presă" />
              </div>
              <div className={styles.sectionRow}>
                <DrawerLink
                  href="#"
                  icon={MdOutlineLocalOffer}
                  text="Oferte speciale"
                />
                <DrawerLink href="#" icon={IoDiamondOutline} text="Carirere" />
              </div>
              <DrawerLink
                href="#"
                icon={PiAmbulance}
                text="Pachete de sănătate"
              />
              <DrawerLink
                href="#"
                icon={FaVirusCovidSlash}
                text="Măsuri Anti Covid"
              />
            </div>
            <div className={styles.section}>
              <div className={styles.sectionField}>
                <div>
                  <ContactLink
                    href="tel:+40350410000"
                    icon={PiPhoneLight}
                    text="Spital: 0350 410-000"
                  />
                  <ContactLink
                    href="tel:+40350801000"
                    icon={PiPhoneLight}
                    text="Imagistica: 0350 801-000"
                  />
                  <ContactLink
                    href="mailto:receptie@balneomedcenter.ro"
                    icon={CiMail}
                    text="receptie@balneomedcenter.ro"
                  />
                  <AdaptiveLocationLink className={styles.contactLink}>
                    <IoLocationOutline
                      className={clsx(styles.smallIcon, styles.contactIcon)}
                    />
                    Strada Ostroveni 142, Râmnicu Vâlcea 240042, România
                  </AdaptiveLocationLink>
                </div>
              </div>
              <div className={styles.sectionRow}>
                <div className={clsx(styles.sectionField, styles.widthAuto)}>
                  <SocialMediaLinks socialNetworks={contactData.social} />
                </div>
                <div className={clsx(styles.sectionField, styles.widthAuto)}>
                  <ScheduleButton
                    scheduleData={scheduleData}
                    onOpen={handleDrawerClose}
                    onClose={handleDrawerOpen}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default NavigationDrawer;
