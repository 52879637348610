import { MedicalStaff } from "@/lib/strapiApiTypes/medicalStaff";
import { stringify } from "querystring";

export const capitalize = (str: string) => {
  if (!str.trim()[0] || !str.trim().slice(1)) return str;

  return str.trim()[0].toUpperCase() + str.trim().slice(1).toLowerCase();
};

export const removeSpecialRomanianChars = (str: string) => {
  const specialChars = {
    Ș: "S",
    ș: "s",
    Î: "I",
    î: "i",
    Ă: "A",
    ă: "a",
    Â: "A",
    â: "a",
    Ț: "T",
    ț: "t",
  };

  for (const key in specialChars) {
    str = str.replace(key, specialChars[key as keyof typeof specialChars]);
  }

  return str;
};

export const createDoctorFullName = (medicalStaff: MedicalStaff) => {
  // Trim names
  const firstName = medicalStaff.firstName.trim();
  const lastName = medicalStaff.lastName.trim();

  // Capitalize first name and take in cosideration it can have 2 first names speared by ' ' or by '-'
  const capitalizedFirstName = firstName
    .split(" ")
    .map((s) =>
      s
        .split("-")
        .map((s2) => capitalize(s2.trim()))
        .join("-")
    )
    .join(" ");

  return `${
    medicalStaff.isDoctor ? "Dr. " : ""
  }${capitalizedFirstName} ${capitalize(lastName)}`;
};

export const normalizeString = (str: string) => {
  // Trim leading and trailing whitespace
  str = str.trim();

  // Replace multiple spaces with a single space
  str = str.replace(/\s{2,}/g, " ");

  // Ensure spaces around dashes
  str = str.replace(/\s*[-]+\s*/g, " - ");

  return str;
};

export const capitalizeEveryWord = (str: string) => {
  return (
    str
      // Split the string into words
      .split(" ")
      .map((s) =>
        s
          // Split the word by dashes and capitalize
          .split("-")
          .map((ss) => capitalize(ss))
          .join("-")
      )
      .join(" ")
  );
};
