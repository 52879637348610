import React from "react";
import styles from "./AdaptiveLocationLink.module.scss";

type Props = { href: string; text: string };

export const AdaptiveLocationDrawerLink = (props: Props) => {
  return (
    <a href={props.href} target="_blank" className={styles.drawerLocationLink}>
      {props.text}
    </a>
  );
};

export default AdaptiveLocationDrawerLink;
