import Link from "next/link";
import { FaArrowRightLong } from "react-icons/fa6";
import styles from "./NavigationDrawer.module.scss";
import { ComponentType } from "react";

type IconComponentProps = {
  className?: string;
};

type DrawerLinkProps = {
  href: string;
  icon: ComponentType<IconComponentProps>;
  arrow?: boolean;
  text: string;
};

const DrawerLink = (props: DrawerLinkProps) => {
  return (
    <Link href={props.href} className={styles.sectionField}>
      <props.icon className={styles.icon} />
      <p className={styles.fieldText}>{props.text}</p>
      {props.arrow && <FaArrowRightLong className={styles.icon} />}
    </Link>
  );
};

export default DrawerLink;
