"use client";

import React, { useEffect, useState } from "react";
import { LuClock8 } from "react-icons/lu";

import styles from "./ScheduleButton.module.scss";
import { createPortal } from "react-dom";
import ScheduleModal from "../ScheduleTable/ScheduleTable";
import { ScheduleData } from "@/lib/strapiApiTypes/schedule";

type Props = {
  scheduleData: ScheduleData;
  onOpen?: () => void;
  onClose?: () => void;
};

const ScheduleButton = (props: Props) => {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);

  const handleScheduleOpen = () => {
    props.onOpen && props.onOpen();
    setIsScheduleOpen(true);
  };

  const handleScheduleClose = () => {
    props.onClose && props.onClose();
    setIsScheduleOpen(false);
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      <button
        onClick={handleScheduleOpen}
        className={`${styles.row} ${styles.rowSchedule}`}
      >
        <LuClock8 className={styles.clockIcon} />
        <p className={styles.text}>
          <span className={styles.isOpenText}>Program de funcționare</span>
          {/* <span className={styles.infoText}>Inchide la 20:00</span> */}
        </p>
        <div className={styles.arrowDown}></div>
      </button>
      {isScheduleOpen &&
        isClient &&
        createPortal(
          <ScheduleModal
            onClose={handleScheduleClose}
            scheduleData={props.scheduleData}
          />,
          document.body
        )}
    </>
  );
};

export default ScheduleButton;
